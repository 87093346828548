import React from "react";
import { graphql } from "gatsby";
import Layout from "../../components/layout";
import Seo from "../../components/seo";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import { useIntl, Link } from "gatsby-plugin-react-intl";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";

const TreatmentCvd = ({ data, location }) => {
  const intl = useIntl();
  const posts = data.allContentfulTherapists.edges;

  return (
    <Layout location={location} locale={intl.locale} mode="beige">
      <Seo
        title={
          intl.locale === "en"
            ? `Top provider of robotics functional treatment`
            : `Top Anbieter für funktionelle robotische Therapie`
        }
        description={
          intl.locale === "en"
            ? `Advanced Neurorehabilitation is available in Bochum, Germany.  We provide Robotics treatment in outpatient neurological rehabilitation setting. `
            : `Fortschrittliche Neurorehabilitation in Bochum, Deutschland. Wir bieten robotische Therapie, im Zuge einer ambulaten Neurorehabilitation an. `
        }
        ogImage="https://images.ctfassets.net/t2ifj85iosf3/3jn9iFwf3ZE1aNn5PxD3e8/1c2e4510a706e74ef74dd9118d153243/E180.JPG?w=1200&h=630&fit=fill"
      />

      <section className="sect sect_TreatmentCvd_first sect-bg-white pt-0">
        <div className="main_back sect-bg-bge"></div>
        <div className="main_copy">
          <h1 className="h1">
            {intl.locale === "en" ? `We are Cyberdyne.` : `Wir sind Cyberdyne`}
          </h1>
          <p className="message-label-bold txt txt-rot">
            {intl.locale === "en"
              ? `About ZNB/ Our therapists`
              : `Das ZNB / Unsere Therapeuten`}
          </p>
          <p>
            {intl.locale === "en"
              ? `The leading provider of robotic and neuro-rehabilitation. We are
  industry leading experts and passionate believers in Cybernics
  Treatment.`
              : `Der führende Anbieter für Robotik und Neurorehabilitation. Wir sind führende Branchenexperten und leidenschaftliche Verfechter der Cybernic-Therapie.`}
          </p>
        </div>
        <div className="main_img">
          <StaticImage
            src="../../images/treatment/znb/treatment-headerimage@2x.png"
            alt="ImgTreatmentCatch"
            className="ImgHeader"
          />
        </div>
      </section>

      <section className="bg-white">
        <div className="container">
          <h2 className="message-lg">
            {intl.locale === "en"
              ? `In cooperation with the Bergmannsheil University Hospital in Bochum,
  we provide the HAL Therapy on the basis of neuromuscular feedback
  therapy.`
              : `In Kooperation mit dem Universitätsklinikum Bergmannsheil bieten wir das HAL-Training basierend auf der neuromuskulären Feedback Therapie an.`}
          </h2>
          <div className="flex-row mb-6">
            <div className="flex-column-7">
              <h2 className="h3">
                {intl.locale === "en"
                  ? `Real passion for the difference we can make to people's lives`
                  : `Echte Passion für die Veränderungen die wir im Leben der Menschen erreichen können.`}
              </h2>
              <p>
                {intl.locale === "en"
                  ? `ZNB (Zentrum für neurorobotales Bewegungstraining) stands for
  Center for neurorobotic motion training, which was created with
  the company foundation.`
                  : `ZNB steht für Zentrum für neurorobotales Bewegungstraining, welches mit der Unternehmensgründung aufgebaut wurde. `}
              </p>
              <p>
                {intl.locale === "en"
                  ? `At ZNB, we envision a place where the most innovative technology
  such as Cybernics consoles and inspires individuals with
  brain-neuromuscular diseases and others to embrace a possibility
  for better change and poentila of improvement within one’s
  physical condition which may seem impossible at this moment. Our
  vigorous and loving team will welcome you from the bottom of our
  hearts.`
                  : `Das ZNB stellen wir uns als einen Ort vor, an dem die neuesten innovativen Technologien wie Cybernics Menschen mit neuromuskulären Erkrankungen inspiriert und der die Möglichkeit für Veränderungen und Verbesserungen der eigenen körperlichen Verfassung schafft, die momentan unmöglich erscheinen. Unser tatkräftiges und und liebevolles Team wird Sie von ganzem Herzen willkommen heißen. `}
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="sect bg-bls">
        <div className="container">
          <div className="flex-row mb-6">
            <div className="flex-column-7">
              <h2 className="h3">
                {intl.locale === "en"
                  ? `Our team in the Center for neurorobotic motion training (ZNB)`
                  : `Unser Team im Zentrum für neurorobotales Bewegungstraining `}
              </h2>
            </div>
          </div>
          <div className="flex-row mb-6 flex-wrap">

            {posts && posts.map(({ node: post }) => {
              return (
                <div className="flex-column-6">
                  <div className="p-4 p-sm-0">
                    <div className="flex-row mb-6 flex-wrap">
                      <div className="flex-column-6">
                        <div className="">
                          <GatsbyImage
                            image={post.thumbnail.gatsbyImageData}
                            className="mb-4"
                            alt={post.name}
                          />
                        </div>
                      </div>
                      <div className="flex-column-6">
                        <div className="p-4 p-sm-0">
                          <p>{post.title}</p>
                          <p className="message-label-bold txt txt-rot">
                            {post.name}
                          </p>
                          <p>{post.description.childMarkdownRemark.rawMarkdownBody}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>

      <section className="sect sect-qa bg-bge">
        <div className="container">
          <p className="h2 mb-5 txt txt-rot">
            {intl.locale === "en"
              ? `Learn more about HAL Treatment`
              : `Mehr über das HAL Training erfahren`}
          </p>
          <Accordion>
            <AccordionItem>
              <AccordionItemHeading className="h1">
                <AccordionItemButton>
                  {intl.locale === "en"
                    ? `Cerebrovascular disease`
                    : `Zerebrovaskulären Erkrankungen`}
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  {intl.locale === "en"
                    ? `From the perspective of motor learning, repetitive movement
  and voluntary exercise are important elements`
                    : `Aus Sicht des motorischen Lernen sind wiederholte Bewegungen und willkürlich durchgeführte Aufgaben wichtige Elemente`}
                </p>
                <Link to="/treatment/cvd/" className="link-button">
                  {intl.locale === "en" ? `Learn more` : `Mehr erfahren`}
                </Link>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading className="h1">
                <AccordionItemButton>
                  {intl.locale === "en"
                    ? `Spinal Cord Injury`
                    : `Rückenmarksverletzungen`}
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  {intl.locale === "en"
                    ? `Locomotor training performed using a neurologically controlled
  cyborg HAL enables the neural activity and repetitive
  excercises of specific tasks.`
                    : `Lokomotionstraining mit dem neurologisch kontrollierten Cyborg HAL ermöglicht neuronale Aktivität und wiederholte Durchführung spezifischer Aufgaben.`}
                </p>
                <Link to="/treatment/spinal/" className="link-button">
                  {intl.locale === "en" ? `Learn more` : `Mehr erfahren`}
                </Link>
              </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem>
              <AccordionItemHeading className="h1">
                <AccordionItemButton>
                  {intl.locale === "en"
                    ? `Progressive neuromuscular diseases`
                    : `Progressive neuromuskuläre Erkrankungen`}
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  {intl.locale === "en"
                    ? `The 5 years Post-marketing surveillance results suggest that
  treatment with the HAL system shows improvement in gait
  related outcome measures despite the progressive nature of the
  diseases.`
                    : `Beobachtungen 5 Jahre nach Einführung des HALs zeigen, dass die HAL-Therapie zu Verbesserungen in Testungen in Bezug auf die Gehfähigkeit führt, trotz der fortschreitenden Art der Erkrankung.`}
                </p>
                <Link to="/treatment/pnd/" className="link-button">
                  {intl.locale === "en" ? `Learn more` : `Mehr erfahren`}
                </Link>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading className="h1">
                <AccordionItemButton>
                  {intl.locale === "en" ? `Testimonials` : `Erfahrungsberichte`}
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  {intl.locale === "en"
                    ? `Discover real stories from real people about how Cyberdyne
  technology changed their lives forever.`
                    : `Entdecken Sie reale Geschichten von Patienten darüber wie Cyberdyne deren Leben für immer verändert hat.`}
                </p>
                <div>
                  <Link to="/treatment/testimonials/" className="link-button">
                    {intl.locale === "en" ? `Learn more` : `Mehr erfahren`}
                  </Link>
                </div>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading className="h1">
                <AccordionItemButton>
                  {intl.locale === "en"
                    ? `Frequently Asked Questions`
                    : `Häufig gestellte Fragen (FAQ)`}
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  {intl.locale === "en"
                    ? `Here are our general frequently asked questions. For more
  specific questions, please contact us directly.`
                    : `Hier finden Sie häufig gestellte Fragen. Für spezifische Fragen kontaktieren Sie uns bitte direkt.`}
                </p>
                <div>
                  <Link to="/treatment/faq/" className="link-button">
                    {intl.locale === "en" ? `Learn more` : `Mehr erfahren`}
                  </Link>
                </div>
              </AccordionItemPanel>
            </AccordionItem>
          </Accordion>
        </div>
      </section>

      <section className="sect sect_TreatmentCvd_2f8as2">
        <div className="container">
          <p className="h3 font-weight-bold">
            {intl.locale === "en"
              ? `Technology For A Better, More Mobile Life – HAL`
              : `Technologie für ein bessers, mobileres Leben - HAL`}
          </p>
          <p>
            {intl.locale === "en"
              ? `Want to bring mobility technology to your clinic? With a simple
  email to Cyberdyne you can.`
              : `Sie wollen Mobilitätstechnologien in Ihrer Klinik anwenden? Eine einfache E-Mail an Cyberdyne genügt.`}
          </p>
          <div>
            <Link to="/contact/" className="link-button">
              {intl.locale === "en" ? `Get in Touch` : `Kontaktieren Sie uns`}
            </Link>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default TreatmentCvd;

export const pageQuery = graphql`
  query ($language: String) {
    allContentfulTherapists(
      sort: {
        order: [DESC],
        fields: [order]
      }
      filter: {
        title: { ne: "do-not-delete-sample" }
        node_locale: { eq: $language }
      }
    ) {
      edges {
        node {
          name
          order
          title
          description {
            childMarkdownRemark {
              rawMarkdownBody
            }
          }
          node_locale
          thumbnail {
            filename
            gatsbyImageData(width: 300, height:450, formats: AUTO)
          }
        }
      }
    }
  }
`;